$(function () {
    if (jQuery.fn.select2 != undefined) {
        jQuery(this).find('.select2-remote').each(function () {
            var defaultPlaceholder = jQuery(this).data('default-placeholder');
            var defaultValue = jQuery(this).data('default-value');
            var option = new Option(defaultPlaceholder, defaultValue, true, true);
            jQuery(this).append(option).trigger('change');
            jQuery(this).select2({
                allowClear: false,
                minimumInputLength: jQuery(this).data('length'),
                formatInputTooShort: 'Veuillez entrer au moins 1 caractère',
                language: {
                    inputTooShort: function (args) {
                        return "Veuillez entrer au moins 1 caractère";
                    },
                    errorLoading: function () {
                        return "Erreur lors du chargement";
                    },
                    noResults: function () {
                        return "Aucun résultat";
                    },
                    searching: function () {
                        return "Recherche en cours...";
                    },
                },
                ajax: {
                    url: jQuery(this).data('remote'),
                    dataType: 'json',
                    delay: 250,
                    data: function (term) {
                        return {
                            q: term // search term
                        };
                    },
                    results: function (d) {
                        return d;
                    }
                },

                containerCssClass: jQuery(this).data('css'),
                formatResult: function (i) {
                    return i.text;
                },
                formatSelection: function (i) {
                    return i.text;
                },

                escapeMarkup: function (markup) {
                    return markup;
                },
            });
        });
    }
});